import React, { useEffect, useState } from "react";
import axios, { AxiosError } from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import MessageList from './MessageList';

function AfnList(props) {

  const [query, setQuery] = useState(props.query);
  const [queryType, setQueryType] = useState("");
  const [displayData, setDisplayData] = useState([]);
  const [showQuery, setShowQuery] = useState(props.showQuery);
  const [count, setCount] = useState(props.count);
  const [missingInit, setMissingInit] = useState(props.missingInit);
  

  const columns = [{
    dataField: 'flightId',
    text: 'Callsign'
  }, {
    dataField: 'hex',
    text: 'Hex',
    hidden: showQuery ? false : true
  }, {
    dataField: 'registration',
    text: 'Registration'
  }, {
    dataField: 'acType',
    text: 'Type'
  },{
    dataField: 'id',
    text: 'Id'
  },
  {
    dataField: 'action',
    text: 'Action'
  }, {
    dataField: 'fir',
    text: 'FIR'
  },  {
    dataField: 'firName',
    text: 'FIR Name'
  },  {
    dataField: 'nextAuth',
    text: 'Next Authority'
  },   
  {
    dataField: 'ges',
    text: 'GES'
  }, {
    dataField: 'timestamp',
    headerStyle: (colum, colIndex) => {
      return { width: '10%', textAlign: 'left' };
    },
    formatter: (value, row) => {
      return moment.unix(value/1000).utc().format("YYMMDD HH:mm");
      
   },
    text: 'Date Time',

  }];

  const addUniqueId = (jsonData) => {
    let index = 0;
    for (let key in jsonData) {
       jsonData[key].uniqueId = index++;
    }
    return jsonData;
  } 
 
  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      console.log(`clicked on row with index: ${rowIndex}`);
     
    },
    onContextMenu: (e, row, rowIndex) => {
      window.open('https://globe.adsbexchange.com/?icao='+row.hex, "_blank", "noreferrer");
    },
  };

  useEffect(() => {
    updateMessageData();
  }, []);


 const handleQueryChange = (event) => {
    setQuery(event.target.value);
  }

  const handleQueryTypeChange = (event) => {
    setQueryType(event.target.value);
  }
   
  const handleMissingInitChange = (event) => {
    setMissingInit(missingInit == true? false : true);
  }
  const rowStyle  = (row, rowIndex) => {
    return {
      fontFamily: "monospace"
      };
  };

  const expandRow = {
    renderer: row => (
      <React.Fragment>
  
        <div>
        <MessageList query={row.hex} showQuery={false} count = {20}></MessageList>
      </div>
      </React.Fragment>
    )
  };
  
  const updateMessageData = () => {

    var token = localStorage.getItem('user-token');

    setDisplayData([]);

    var url;


    if(missingInit){

       url = 'https://jlogger.net/api/v-afn-noinits?&filter[order][0]=flightId&filter[order][1]=timeStamp%20DESC';

    }

    else{ 
      
      if (query.length > 0) {


        if (queryType == "FIR") {
          url = 'https://jlogger.net/api/afns?&filter[order]=timeStamp%20DESC&filter[limit]=' + count + '&filter[where][fir][like]=' + query + '%25' 
        } 
        else {
          url = 'https://jlogger.net/api/afns?&filter[order]=timeStamp%20DESC&filter[limit]=' + count + '&filter[where][hex]=' + query;
        }
      }
      else {
     
        url = 'https://jlogger.net/api/afns?&filter[order]=timeStamp%20DESC&filter[limit]=' + count;
      }
    
    }    
    
    axios.get(url, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(res => {
        setDisplayData(addUniqueId(res.data))

      })

      .catch(error => {
        console.log(error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);

          if(error.response.status == '401'){

            alert('Session has timed out');

          }else{

            alert('Error getting flight data');
          }
          

        } else {

          alert('Error getting message data');
        }
      });

  }

  let template;
  if (showQuery) {

    template =
     <div class="mb-3">
     <Form onSubmit={e => {e.preventDefault()}}>
      <Row style={{width: '750px'}} className="align-items-center">
      <Col xs="auto">
        <Form.Select aria-label="Select MsgType" value={queryType} onChange={handleQueryTypeChange}  disabled = {missingInit}>
          <option>Select Search Type</option>
          <option value="HEX">HEX</option>
          <option value="FIR">FIR</option>
        </Form.Select>
        </Col>
        <Col xs="auto"> 
          <Form.Control type = "text" placeholder={queryType} value={query} onChange={handleQueryChange} disabled = {missingInit} />
        </Col>

        <Col xs="auto">
        <Form.Check type="switch" id="custom-switch" label="Missing INIT" checked={missingInit} value={missingInit} onClick={handleMissingInitChange}
        />
        </Col>
        <Col xs="auto">
        <Button variant="light" onClick={e => {e.preventDefault(); updateMessageData()}} >
        Submit
      </Button>
        </Col>
      </Row>
    </Form>
    </div>
    }

  return (
    
    <React.Fragment>
    <>
    {template}
    <div >
      <BootstrapTable 
        bootstrap4
        keyField="uniqueId"
        data={displayData}
        rowStyle={ rowStyle }   
        columns={columns}
        hover={true}
        rowEvents={rowEvents}
        condensed ={true}
        expandRow={expandRow}
        />
    </div>
    </>
    </React.Fragment>
  )
}
export default AfnList;