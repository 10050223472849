import React,{useEffect,useState} from 'react';
import { Button, Nav, Modal, Row } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from "react-router-dom";




const PortalNavbar = () => {
    const navigate = useNavigate();
    
    const [showInfo, setShowInfo] = useState(false);

    const logout = () => {
        localStorage.clear();
        navigate('/auth/login');
    }

    const handleClose = () => setShowInfo(false);

    const handleShow = () => setShowInfo(true);
    
    const styles = {
       
        row: {
            marginLeft: 2,
            marginRight: 2
        }
    };
   
   
    return (
       <>
       <Modal show={showInfo} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Some usage tips</Modal.Title>
        </Modal.Header>
        <Modal.Body >
            <Row className="mb-2" style={styles.row}>
            Left click on a flight to expand the message list

            </Row>
            <Row className="mb-2" style={styles.row}>
            Right click on a flight to open this hex in adsbexchange 
            </Row>
            <Row className="mb-2" style={styles.row}>
            Click on an SKV link to open route in skyvector 
            </Row>
            <Row className="mb-2" style={styles.row}>
            Mouse hover over ICAO cell to see airfield name 
            </Row>
            <Row className="mb-2" style={styles.row}>
            Mouse hover over registration cell to see aircraft ID 
            </Row>
 
        </Modal.Body>
       
      </Modal>

        
        <React.Fragment>
            <Navbar bg="light" expand="lg" className="navbar-light">
                <Container>
                    <Navbar.Brand>JLogger | Portal</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link>
                                <Button className="btn-info" onClick={handleShow}>Info</Button>
                            </Nav.Link>
                            <Nav.Link>
                                <Button className="btn-warning" onClick={logout}>Logout</Button>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </React.Fragment>

        </>
    );
}
export default PortalNavbar;