import React, { useEffect, useState } from "react";
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from 'react-select'

function MessageList(props) {

  const [query, setQuery] = useState(props.query);
  const [queryType, setQueryType] = useState("");
  const [displayData, setDisplayData] = useState([]);
  const [showQuery, setShowQuery] = useState(props.showQuery);
  const [count, setCount] = useState(props.count);
  const [gesValues, setGesValues] = useState([]);
  const [advancedSearch, setAdvancedSearch] = useState(false);

  const gesOptions = [
    { value: 'GES:90', label: 'GES:90' },
    { value: 'GES:43', label: 'GES:43' },
    { value: 'GES:44', label: 'GES:44' },
    { value: 'GES:02', label: 'GES:02' },
    { value: 'GES:05', label: 'GES:05' },
    { value: 'GES:D0', label: 'GES:D0' },
    { value: 'GES:82', label: 'GES:82' },
    { value: 'GES:85', label: 'GES:85' },
    { value: 'GES:50', label: 'GES:50' },
    { value: 'GES:C1', label: 'GES:C1' },
    { value: 'GES:C5', label: 'GES:C5' },

  ]

  const columns = [{
    dataField: 'flightId',
    text: 'Callsign'
  }, {
    dataField: 'hex',
    text: 'Hex',
    hidden: showQuery ? false : true
  }, {
    dataField: 'msgType',
    text: 'Type'
  }, {
    dataField: 'msg',
    text: 'Message',
    headerStyle: (colum, colIndex) => {
      return { width: '70%', textAlign: 'left' };
    },
    formatter: (value, row) => {
      if (row.msgType == 'CAL') {
        return value.substring(0, 55);
      }
      else { return value }
    },
    
    attrs: (cell, row, rowIndex, colIndex) => ({ title: row.msg })

  }, {
    dataField: 'ges',
    text: 'GES'
  }, {
    dataField: 'timestamp',
    headerStyle: (colum, colIndex) => {
      return { width: '10%', textAlign: 'left' };
    },
    formatter: (value, row) => {
      return moment.unix(value / 1000).utc().format("YYMMDD HH:mm");

    },
    text: 'Date Time',

  }];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      console.log(`clicked on row with index: ${rowIndex}`);
      if (row.msgType == 'SKV') {
        window.open(row.msg, "_blank", "noreferrer");
      }

    },
    onContextMenu: (e, row, rowIndex) => {
      console.log(`clicked on row with index: ${rowIndex}`);
    }
  };

  useEffect(() => {
    updateMessageData();
  }, []);


  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  }

  const handleQueryTypeChange = (event) => {
    setQueryType(event.target.value);
  }

  const handleAdvancedSearchChange = (event) => {
    setAdvancedSearch(advancedSearch == true ? false : true);
  }

  const handleGesChange = (event) => {
    setGesValues(event);

  }
  const addUniqueId = (jsonData) => {
    let index = 0;
    for (let key in jsonData) {
      jsonData[key].uniqueId = index++;
    }
    return jsonData;
  }

  const rowStyle = (row, rowIndex) => {
    return {
      fontFamily: "monospace",
      wordBreak: "break-all"
    };
  };

  const submitLoginForm = (event) => {

    updateMessageData();
  }

  const updateMessageData = () => {

    var token = localStorage.getItem('user-token');

    setDisplayData([]);

    var url = 'https://jlogger.net/api/msgs?'

    if(advancedSearch && gesValues.length == 1)
    {
       url += '&filter[where][ges][eq]=' + gesValues[0].value;
    }
    else if(advancedSearch && gesValues.length > 1)
    {
      gesValues.forEach((element) => url += '&filter[where][ges][inq]=' + element.value);
    }

    if (query.length > 0) {

      if (queryType.length == 0 || queryType == 'ALL') {

        url += '&filter[where][hex]=' + query + '&filter[where][msgType][neq]=ADS';

      }

      else {
        url += '&filter[where][hex]=' + query + '&filter[where][msgType]=' + queryType;
      }

    } else {

      if (queryType.length > 0 && !(queryType == 'ALL')) {

        url += '&filter[where][msgType]=' + queryType;
      }
      else {

        url += '&filter[where][msgType][nin]=LOG&filter[where][msgType][nin]=ADS';
      }
    }

    url += '&filter[order]=timeStamp%20DESC&filter[limit]=' + count;

    axios.get(url, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(res => {
        setDisplayData(addUniqueId(res.data))

      })

      .catch(error => {
        console.log(error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);

          if (error.response.status == '401') {

            alert('Session has timed out');

          } else {

            alert('Error getting flight data');
          }


        } else {

          alert('Error getting message data');
        }
      });

  }

  let advSearch;
  {
    if (advancedSearch) {
      advSearch =

        <Row style={{ width: '410px', marginTop: 9 }} className="align-items-center" >
          <Col>
            <Select 
            options={gesOptions}
            onChange={handleGesChange} value={gesValues}
            isMulti
            placeholder="Select GES" />
          </Col>
        </Row>
    }
  }

  let template;
  if (showQuery) {

    template = 
    <div class="mb-3">
      <Form onSubmit={e => { e.preventDefault() }}>
        <Row style={{ width: '750px' }} className="align-items-center">
          <Col>
            <Form.Select aria-label="Select MsgType" value={queryType} onChange={handleQueryTypeChange} >
              <option>Select Msg Type</option>
              <option value="ALL">ALL</option>
              <option value="WXR">WXR</option>
              <option value="FPN">FPN</option>
              <option value="SKV">SKV</option>
              <option value="INI">INI</option>
              <option value="FTX">FTX</option>
              <option value="TXT">TXT</option>
              <option value="RPO">RPO</option>
              <option value="PWI">PWI</option>
              <option value="CAL">CAL</option>
              <option value="LOG">LOG</option>
              <option value="AT1">AT1</option>
              <option value="ADS">ADS</option>

            </Form.Select>
          </Col>
          <Col>
            <Form.Control type="text" placeholder="HEX" value={query} onChange={handleQueryChange} />
          </Col>
          <Col xs="auto">
              <Form.Check type="switch" id="adv-switch" label="Advanced" checked={advancedSearch} value={advancedSearch} onClick={handleAdvancedSearchChange}
              />
          </Col>
          <Col>
            <Button variant="light" onClick={e => { e.preventDefault(); updateMessageData() }} >
              Submit
            </Button>
          </Col>
        </Row>
        {advSearch}
      </Form>
      </div>
  }

  return (
    <React.Fragment>
      <>
        {template}
        <div >
          <>
            <div>
              <BootstrapTable
                bootstrap4
                keyField="uniqueId"
                data={displayData}
                rowStyle={rowStyle}
                columns={columns}
                hover={true}
                rowEvents={rowEvents}
                condensed={true}
              />
            </div>
          </>
        </div>
      </>
    </React.Fragment>
  )
} export default MessageList;
