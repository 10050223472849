import React,{useEffect,useState} from 'react';
import { Container } from 'react-bootstrap';
import FlightList from './FlightList';
import MessageList from './MessageList';
import AfnList from './AfnList';


const Home=()=>{

    const [showFlights, setShowFlights] = useState(true);
    const [showMessages, setShowMessages] = useState(false);
    const [showAfn, setShowAfn] = useState(false);
    const [showKibana, setShowKibana] = useState(false);

    const buttonFlightsClicked = () => {

        setShowFlights(true);
        setShowMessages(false);
        setShowAfn(false);
        setShowKibana(false);
    }

    const buttonMessagesClicked = () => {

        setShowFlights(false);
        setShowMessages(true);
        setShowAfn(false);
        setShowKibana(false);
    }
    const buttonAfnClicked = () => {

        setShowFlights(false);
        setShowMessages(false);
        setShowAfn(true);
        setShowKibana(false);
    }

    const buttonKibanaClicked = () => {

        setShowFlights(false);
        setShowMessages(false);
        setShowAfn(false);
        setShowKibana(true);
        
    }

    useEffect(()=>{

    },[])

    let flightsFragment;
    if(showFlights){

        flightsFragment = <>
        <FlightList query={""}></FlightList>
        </>
    }
    let messagesFragment;
    if(showMessages){

        messagesFragment = <>
        <MessageList query={""} showQuery={true} count={200}></MessageList>
        </>
    }
    let afnFragment;
    if(showAfn){

        afnFragment = <>
        <AfnList query={""} showQuery={true} count={200} missingInit={false}></AfnList>
        </>
    }
    let kibanaFragment;
    if(showKibana){

        afnFragment = <>
        <iframe
            width="100%"
            height="1000"
            src="https://jlogger.net/kibana/app/discover#/view/43d86bc0-5bc0-11ef-b26c-c34ae2035ac0?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-15m,to:now))&embed=true&_a=(columns:!(ges,aesId,type,reg,owner,message,mil),filters:!(),grid:(),hideChart:!f,index:'4fae1510-5bbd-11ef-b26c-c34ae2035ac0',interval:auto,query:(language:kuery,query:''),sort:!(!('@timestamp',desc)))"
        ></iframe>
        </>
    }
    return(

     
        <React.Fragment>
        <Container className="my-15" style={{ marginTop: '12px', marginBottom: '12px'}} >
        <button type="button" class="btn btn-secondary" style={{ marginRight: '12px'}}  onClick={() => buttonFlightsClicked()}>Flights</button>
        <button type="button" class="btn btn-secondary" style={{ marginRight: '12px'}}  onClick={() => buttonMessagesClicked()}>Messages</button>
        <button type="button" class="btn btn-secondary" style={{ marginRight: '12px'}}  onClick={() => buttonAfnClicked()}>AFN</button>
        <button type="button" class="btn btn-secondary" style={{ marginRight: '12px'}}  onClick={() => buttonKibanaClicked()}>Kibana</button>
        </Container>
        
        <Container className="my-5">
        <>
        <div>
             {flightsFragment} 
             {messagesFragment} 
             {afnFragment}
             {kibanaFragment}
             
        </div>
        </>
        </Container>
        </React.Fragment>
    )
}

export default Home;