import React, { useEffect, useState } from "react";
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import MessageList from './MessageList';
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from 'react-select'
import moment from 'moment';


function FlightList(props) {

  const [query, setQuery] = useState("");
  const [queryType, setQueryType] = useState("");
  const [displayData, setDisplayData] = useState([]);
  const [gesValues, setGesValues] = useState([]);
  const [advancedSearch, setAdvancedSearch] = useState(false);


  const gesOptions = [
    { value: 'GES:90', label: 'GES:90' },
    { value: 'GES:43', label: 'GES:43' },
    { value: 'GES:44', label: 'GES:44' },
    { value: 'GES:02', label: 'GES:02' },
    { value: 'GES:05', label: 'GES:05' },
    { value: 'GES:D0', label: 'GES:D0' },
    { value: 'GES:82', label: 'GES:82' },
    { value: 'GES:85', label: 'GES:85' },
    { value: 'GES:50', label: 'GES:50' },
    { value: 'GES:C1', label: 'GES:C1' },
    { value: 'GES:C5', label: 'GES:C5' },

  ]

  const columns = [{
    dataField: 'flightId',
    text: 'Callsign'
  }, {
    dataField: 'acType',
    text: 'Type'
  }, {
    dataField: 'hex',
    text: 'Hex'
  }, {
    dataField: 'registration',
    text: 'Registration',
    attrs: (cell, row, rowIndex, colIndex) => ({ title: row.id })
  }, {
    dataField: 'depIcao',
    text: 'Departure ICAO',
    attrs: (cell, row, rowIndex, colIndex) => ({ title: row.depFld })
  }, {
    dataField: 'arrIcao',
    text: 'Arrival ICAO',
    attrs: (cell, row, rowIndex, colIndex) => ({ title: row.arrFld })
  }, {
    dataField: 'dateop',
    text: 'Date'
  }, {
    dataField: 'depTime',
    text: 'Dep Time',
    attrs: (cell, row, rowIndex, colIndex) => ({ title: row.ete })
  }, {
    dataField: 'msnNumber',
    text: 'Mission Number',
    attrs: (cell, row, rowIndex, colIndex) => ({ title: row.msnSuf })
  }, {
    dataField: 'ges',
    text: 'GES'
  }, {
    dataField: 'timestamp',
    text: 'Received UTC',
    hidden: false,

    formatter: (value, row) => {
      return moment.unix(value / 1000).utc().format("YYYY-MM-DD HH:mm");

    },
  }];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {

    },
    onContextMenu: (e, row, rowIndex) => {
      window.open('https://globe.adsbexchange.com/?icao=' + row.hex, "_blank", "noreferrer");
    },
    onMouseEnter: (e, row, rowIndex) => {

    }
  };

  const expandRow = {
    renderer: row => (
      <React.Fragment>

        <div>
          <MessageList query={row.hex} showQuery={false} count={20}></MessageList>
        </div>
      </React.Fragment>
    )
  };


  useEffect(() => {
    updateFlightData();
  }, []);

  const addUniqueId = (jsonData) => {
    let index = 0;
    for (let key in jsonData) {
      jsonData[key].uniqueId = index++;
    }
    return jsonData;
  }

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
  }

  const handleQueryTypeChange = (event) => {
    setQueryType(event.target.value);
  }

  const handleAdvancedSearchChange = (event) => {
    setAdvancedSearch(advancedSearch == true ? false : true);
  }

  const handleGesChange = (event) => {
    setGesValues(event);

  }
  const mystyle = {
    fontFamily: "monospace"
  };

  const rowStyle = (row, rowIndex) => {
    return mystyle;
  };

  const updateFlightData = () => {

    var token = localStorage.getItem('user-token');

    setDisplayData([]);

    var url = 'https://jlogger.net/api/inits?';

    if(advancedSearch && gesValues.length == 1)
    {
       url += '&filter[where][ges][eq]=' + gesValues[0].value;
    }
    else if(advancedSearch && gesValues.length > 1)
    {
      gesValues.forEach((element) => url += '&filter[where][ges][inq]=' + element.value);
    }


    if (query.length > 0) {

      if (queryType == "Callsign") {
        url +=  '&filter[where][flightId][like]=' + query + '%25'
      }
      else if (queryType == "HEX") {
        url += '&filter[where][hex][like]=' + query + '%25'
      }
      else if (queryType == "Type") {
        url += '&filter[where][acType][like]=' + query + '%25'
      }
      else if (queryType == "MissionNumber") {
        url += '&filter[where][msnNumber][like]=%25' + query + '%25'
      }
      else if (queryType == "ICAO") {
        url += '&filter[where][or][0][arrIcao][like]=' + query + '%25' + '&filter[where][or][1][depIcao][like]=' + query + '%25';
      } 
    } 
   
    url += '&filter[order]=timeStamp%20DESC&filter[limit]=200'

   axios.get(url, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(res => {
        console.log('Response from main API: ', res)
        console.log('Home Data: ', res.data.ad)
        setDisplayData(addUniqueId(res.data))

      })

      .catch(error => {
        console.log(error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);

          if (error.response.status == '401') {

            alert('Session has timed out');

          } else {

            alert('Error getting flight data');
          }


        } else {

          alert('Error getting flight data');
        }
      });

  }

  let advSearch;
  {
    if (advancedSearch) {
      advSearch =

        <Row style={{ width: '410px', marginTop: 9 }} className="align-items-center" >
          <Col>
            <Select 
            options={gesOptions}
            onChange={handleGesChange} value={gesValues}
            isMulti
            placeholder="Select GES" />
          </Col>
        </Row>
    }
  }

  let template;
  {
    template =
      <div class="mb-3">
        <Form onSubmit={e => { e.preventDefault() }}>
          <Row style={{ width: '750px' }} className="align-items-center">
            <Col>
              <Form.Select aria-label="Select MsgType" value={queryType} onChange={handleQueryTypeChange} >
                <option>Select Search Type</option>
                <option value="ICAO">ICAO</option>
                <option value="Callsign">Callsign</option>
                <option value="HEX">HEX</option>
                <option value="Type">Type</option>
                <option value="MissionNumber">Mission Number</option>

              </Form.Select>
            </Col>
            <Col>
              <Form.Control type="text" placeholder={queryType} value={query} onChange={handleQueryChange} />
            </Col>

            <Col xs="auto">
              <Form.Check type="switch" id="adv-switch" label="Advanced" checked={advancedSearch} value={advancedSearch} onClick={handleAdvancedSearchChange}
              />
            </Col>
            <Col>
              <Button variant="light" onClick={e => { e.preventDefault(); updateFlightData() }} >
                Submit
              </Button>
            </Col>
          </Row>
          {advSearch}
        </Form>
      </div>
  }
  return (
    <React.Fragment>
      <>
        {template}
        <div>
          <>
            <div>
              <BootstrapTable
                bootstrap4
                keyField="uniqueId"
                data={displayData}
                headerClasses={rowStyle}
                rowStyle={rowStyle}
                columns={columns}
                hover={true}
                striped={true}
                rowEvents={rowEvents}
                expandRow={expandRow}
                condensed={true}
              />
            </div>
          </>
        </div>
      </>
    </React.Fragment>
  )

} export default FlightList;